<template>
    <div class="spalla container-espe" v-if="projectdata" @click="this.closeSpalla">
        <div class="extra">
            <button @click="closeSpalla" class="spalla-close">
                <img src="/close-w.svg" alt="">
            </button>

            <div class="progetto-dettaglio spalla-container container-espe">

                <div class="container-espe" v-if="projectdata.attributes">
                    <div class="info">
                        <h5 class="title">{{ projectdata.attributes.title }}</h5>
                        <div v-if="projectdata.field_project_settore" class="field"
                            :style="this.fieldStyle(projectdata.field_project_settore.attributes.drupal_internal__tid)"></div>
                    </div>
                </div>

                <div class="container-espe gray-bg">
                    <div class="info">
                        <div class="white-tile raccolti">
                            <div class="donated">{{ parseFloat(projectdata.attributes.field_project_raccolti * 2.00).toFixed(2) }} <span>€</span> </div>
                            <div class="donated-icon"></div>
                            <div class="donation-bar" :style="'--wp: ' + this.percentage + '%;'">
                                <div class="raccolti"></div>
                                <div class="bar"></div>
                                <div class="goal"></div>
                                <div class="label">
                                    <span>{{ this.percentage + '%' }}</span> di
                                    <span>{{ projectdata.attributes.field_project_goal + ' €' }}</span>
                                </div>
                            </div>
                        </div>

                        <div class="organization">
                            <div class="content" v-if="projectdata.field_project_promotori[0]">
                                <p>Organizzato da</p>
                                <div class="loghi">
                                    <div v-for="(image, index) in projectdata.field_project_promotori" :key="index">
                                        <img :src="promotoriSrc(image)" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container-espe">
                    <h2>IL PROGETTO</h2>
                    <!--<p v-if="projectdata.attributes.field_project_descrizione.value.length>750" v-html="this.readmore
                            ? projectdata.attributes.field_project_descrizione.value : 
                            (projectdata.attributes.field_project_descrizione.value.substr(0, 750) + ' ...')"></p>-->
                    <p v-html="projectdata.attributes.field_project_descrizione.value"></p>
                    <!--<div v-if="projectdata.attributes.field_project_descrizione.value.length>=750" class="button-container"
                        style="text-align: left;">
                        <button class="btn" style="padding: .5em 1em; max-width: 200px;"
                            @click.native="this.accordion">{{ this.readmore ? 'Chiudi' : 'Leggi di più'}}</button>
                    </div>-->
                    <br>
                    <images :imagedata="projectdata" :fieldname="'field_project_foto'"
                        :breakstyles="{'0': 'sm_image', '576': 'md_image', '992': 'lg_image'}"></images>
                </div>

                <div class="container-espe">
                    <h2>CHI SIAMO</h2>
                    <p v-html="projectdata.attributes.field_project_chi_siamo.value">
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { fieldColorProject, fieldSrcProject } from "../libs/utils.js"
import Images from '../components/Images.vue'
export default {
    name: 'spalla-project',
    props: {
        projectdata: Object,
        filtersdata: Array
    },
    components: {
        Images
    },
    data: () => {
        return {
            readmore: false, 
        }
    },
    methods: {
        promotoriSrc(img) {
            return process.env.VUE_APP_ENDPOINT + img.attributes.uri.url
        },
        closeSpalla() {
            this.$emit('close-spalla')
        },
        accordion() {
            this.readmore = !this.readmore
        },
        fieldStyle(image) {
            var f = this.filtersdata.find(i => i.attributes.drupal_internal__tid == image)
            var n = f ? f.attributes.name : 'not-found'

            return '--background-color: ' + fieldColorProject(image) + ';' +
                '--background-image: url(' + fieldSrcProject(image) + ');' +
                '--field-text: "' + n + '";' +
                '--field-width: ' + n.length * 9 + 'px;'
        }
    },
    mounted() {
        console.log(this.projectdata)
    },
    computed: {
        percentage() {
            return Math.round(this.projectdata.attributes.field_project_raccolti * 2.00 * 100 / this.projectdata.attributes.field_project_goal)
        },
    }
}
</script>

<style lang="scss" scoped>
@import '../styles/variables.scss';

.spalla {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(#000, .8);
    width: 100%;
    height: 100%;
    z-index: 100;
    padding-top: 80px;

    .white-tile {
        box-shadow: none;
    }

    .extra {
        position: relative;
        .spalla-close {
            position: absolute;
            right: 0;
            top: 0;
            transform: translateY(-25%) translateX(25%);
            background-color: $mainColor;
            width: 60px;
            height: 60px;
            border-radius: 30px;
            outline: none;
            border: none;
            z-index: 3;
            img {
                width: 30px;
                height: 30px;
                object-fit: contain;
            }
        }
    }

    .spalla-container {
        background-color: #fff;
        height: calc(100vh - 120px);//150px);
        overflow-y: scroll;
        border-radius: 20px;
        padding: 20px 0;
        .container-espe {
            padding-left: 10px;
            padding-right: 10px;
            .info {
                position: relative;
            }
        }
    }
}

@media(min-width: 576px) {
    .spalla {
        .spalla-container {
            .container-espe {
                padding-left: 40px;
                padding-right: 40px;
            }
        }
    }
}

</style>