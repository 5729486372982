<template>
<div>
    <spalla-project v-if="showSpalla" :filtersdata="this.filtersData" :projectdata="this.projectData[this.selectedProject]" @close-spalla="this.closeSpalla"></spalla-project>

    <div id="totop" class="no-header-template template-page gray-bg doppio-layer richiedi-confronto">

        <div class="container-espe intro fullwidth gray-bg">
            <div class="cover">
                    <h4 class="titolo" id="richiedi">Invia la richiesta</h4>
                    <div :class="{'steps-h': true, 'colored': this.activeStep==5 }">
                        <div class="num">{{ this.activeStep + '/5' }}</div>
                </div>
            </div>
        </div>

        <div class="white-bg backstep scheda" style="margin-top: 0;">
            <div class="container-espe">

                <form v-if="activeStep<=5" ref="form" class="modulo-esperto" @submit.prevent novalidate="true">
                    <div :class="{'step1': true, 'form-step': true, 'active-step': this.activeStep==1}">
                        <p class="bold light">
                            Il confronto verrà organizzato in maniera <span>autonoma da te e l'esperto</span> utilizzando la modalità più pratica per voi.
                        </p>
                        <div class="images">
                            <div class="logo1"></div>
                            <div class="logo2"></div>
                            <div class="logo3"></div>
                        </div>
                        <br>
                        <p class="bold">
                            Per questo ti chiediamo di rispondere ad alcune domande.
                        </p>
                        <p>
                            Dopo aver inviato la richiesta riceverai una mail con le istruzioni per organizzare il confronto.
                        </p>
                    </div>

                    <div :class="{'step2': true, 'form-step': true, 'active-step': this.activeStep==2}">
                        <h4 class="titolo-form">Ti ricordiamo che Experotary è dedicato solo a studenti.</h4>
                        <div class="scuola scuola1">
                            <input type="radio" id="scuola1" name="scuola" value="1" v-model="formData.Scuola">
                            <label for="scuola1">SONO UNO STUDENTE DI SCUOLA SUPERIORE</label>
                        </div>
                        <div class="scuola scuola2">
                            <input type="radio" id="scuola2" name="scuola" value="2" v-model="formData.Scuola">
                            <label for="scuola2">SONO UNO STUDENTE DI SCUOLA SUPERIORE APPENA DIPLOMATO</label>
                        </div>
                        <div class="scuola scuola3">
                            <input type="radio" id="scuola3" name="scuola" value="3" v-model="formData.Scuola">
                            <label for="scuola3">SONO UNO STUDENTE UNIVERSITARIO</label>
                        </div>

                        <br>
                        <h3 class="titolo-form">INSERISCI I TUOI DATI</h3>
                        <div class="campo"><input required v-model="formData.Nome" type="text" name="Nome" placeholder="Nome" @change="correct" :class="{errore: erroreNome}"><span class="tick"></span></div>
                        <div class="campo"><input required v-model="formData.Cognome" type="text" name="Cognome" placeholder="Cognome" @change="correct" :class="{errore: erroreCognome}"><span class="tick"></span></div>
                        <div class="campo"><input v-model="formData.LuogoNascita" type="text" placeholder="Luogo di Nascita"><span class="tick"></span></div>
                        <!-- onfocus e onfocusout cambiano tipo di input per far vedere il placeholder custom -->
                        <div class="campo"><input v-model="formData.DataNascita" type="text" placeholder="Data di Nascita" @focus="focusDate" id="input_date">
                        <!--  onfocus="(this.type='date')" onfocusout="(this.type='text')"  -->
                        </div>
                        <div class="campo"><input v-model="formData.Indirizzo" type="text" placeholder="Indirizzo"><span class="tick"></span></div>

                        <h3 class="titolo-form">DATI DI CONTATTO</h3>
                        <p>Questi dati saranno forniti all'esperto per contattarti</p>
                        <div class="campo"><input required v-model="formData.Email" id="email" type="email" name="Email" placeholder="Email" @change="correct" :class="{errore: erroreEmail}"><span class="tick"></span></div>
                        <div class="campo"><input v-model="formData.Cellulare" type="tel" name="Cellulare" placeholder="Cellulare"><span class="tick"></span></div>

                        <br>
                        <p v-if="erroreAutorizzo" style="color: #FEAF40;">Devi accettare il trattamento dei dati</p>
                        <div class="auth">
                            <input required type="checkbox" name="Autorizzo" id="autorizzo" v-model="formData.Autorizzo" @change="correct">
                            <p><label for="autorizzo">Autorizzo il trattamento dei miei dati personali raccolti ai sensi del Dlgs 196 del 30 giugno 2003 e dell’art. 13 GDPR (Regolamento UE2016/679) per le finalità dell’iniziativa “EXPEROTARY”</label></p>
                        </div>
                    </div>

                    <div v-if="this.expertData && this.expertData.attributes" :class="{'step3': true, 'form-step': true, 'active-step': this.activeStep==3}">
                        <h3 class="titolo-form">STAI INVIANDO LA RICHIESTA A</h3>
                        <div class="tile">
                            <div class="profilo" :style="fotoprofilo">
                            </div>

                            <input type="text" hidden name="ExpertId" :value="currentExpertId">
                            <h4 class="esperto">{{ expertData.attributes.field_name + ' ' + expertData.attributes.field_surname }}</h4>
                            <p class="bold ruolo">{{ expertData.attributes.field_expert_professione }}</p>
                        </div>
                        
                        <br>
                        <h3 class="titolo-form">DI COSA VORRESTI PARLARE?</h3>
                        <p>Anticipa brevemente a <span>{{ expertData.attributes.field_name + ' ' + expertData.attributes.field_surname }}</span> i temi su cui vorresti confrontarti</p>
                        <textarea @change="correct" :class="[liveCountDown(formData.DiCosaParlare) > 100 ? 'errore' : '']" type="textarea" rows="12" placeholder="Massimo 100 parole" v-model="formData.DiCosaParlare"></textarea>

                        <h3 class="titolo-form">RACCONTACI DI TE</h3>
                        <p>Per preparare l'incontro, <span>{{ expertData.attributes.field_name + ' ' + expertData.attributes.field_surname }}</span> vorrebbe sapere qualcosa su di te</p>
                        <textarea @change="correct" :class="[liveCountDown(formData.Raccontaci) > 100 ? 'errore' : '']" type="textarea" rows="12" placeholder="Massimo 100 parole" v-model="formData.Raccontaci"></textarea>
                    </div>

                    <div :class="{'step4': true, 'form-step': true, 'active-step': this.activeStep==4}">
                        <div v-if="!this.donationData.Donazione" style="text-align: center;">
                            <h3 class="titolo-form">IL CONFRONTO CON L'ESPERTO CHE HAI SCELTO TE LO <strong>REGALIAMO</strong> NOI</h3>
                            <p>La donazione non è obbligatoria ma se deciderai di farla Experotary aggiungerà un contributo di pari valore in modo da raddoppiare l'impatto.</p>
                            <p style="color: #999; font-size: 14px;">[1] Fino ad un massimo di 1.000,00€</p>
                            <hr>
                            <br>
                            <h4 class="titolo-form">DECIDI CHI SOSTENERE</h4>
                            <div class="projects">
                                <project-tile
                                    type="button"
                                    :donation="true"
                                    v-for="(project, index) in this.projectData" 
                                    :key="'project' + index"
                                    :projectdata="project" 
                                    :filtersdata="filtersData"
                                    :routes="routesData"
                                    :addclass="selectedProject==index ? 'selected' : ''"
                                    
                                    @select="select(index)"
                                    @open-detail="openSpalla(index)">

                                    <div v-if="selectedProject!=index" class="">
                                        <button class="btn inside">Supporta il progetto</button>
                                    </div>

                                    <div v-else :class="{'extra-donation': true, 'visible': selectedProject==index}">
                                        <div class="campo euro">
                                            <input type="number" step=".05" v-model="donations['donation'+index]">
                                        </div>
                                        <div :id="'ppbutton'+index"></div>
                                    </div>
                                

                                </project-tile>
                            </div>
                        </div>

                        <div v-else class="container-espe" style="text-align: center;">
                            <div class="white-tile blue-tile">
                                <h3 class="titolo">Grazie {{ formData.Nome + " " + formData.Cognome }} per il tuo supporto!</h3>
                                <h4 class="bold self">
                                    <span>{{ donationData.Donazione + "€ " }}</span>
                                    <span class="rotary-money">{{ " +" + donationData.Donazione + "€ " }}</span>
                                    <br class="acapo">
                                    <span class="rotary">di Rotary</span>
                                </h4>
                                <img src="../assets/donated-w.svg" alt="">
                                <!--<h4 class="rotary-money">{{  }}</h4>
                                <p class="rotary">di Rotary</p>-->
                            </div>
                            <br>
                            <h4>Puoi procedere con la richiesta di confronto</h4>
                        </div>
                        
                    </div>

                    <div :class="{'step5': true, 'form-step': true, 'active-step': this.activeStep==5}" style="text-align: center;">
                        <!--<h5 style="text-transform: uppercase; font-size: 1rem;">
                            Ricordati che i nostri esperti mettono a disposizione il loro tempo, quindi <strong style="font-weight: 700;">conferma</strong> solo se sei sicuro che sfrutterai quest'occasione.
                        </h5>-->
                        <img src="../assets/sent.svg" alt="">
                        <!--<p>Confermando la richiesta invieremo una mail a te e all’esperto che hai scelto con tutte le informazioni fornite in modo che possiate organizzare in maniera autonoma il confronto.</p>-->
                        <br>
                        <h4>STAI PER INVIARE UNA RICHIESTA DI CONFRONTO A:</h4>
                        <div v-if="this.expertData && this.expertData.attributes" class="tile">
                            <div class="profilo" :style="fotoprofilo">
                            </div>

                            <input type="text" hidden name="ExpertId" :value="currentExpertId">
                            <h4 class="esperto">{{ expertData.attributes.field_name + ' ' + expertData.attributes.field_surname }}</h4>
                            <p class="bold ruolo">{{ expertData.attributes.field_expert_professione }}</p>
                        </div>
                    </div>

                    <div class="navigators">
                        <button :class="{'btn': true, 'white-btn': true, 'hidden': this.activeStep==1}" type="button" @click="prevStep">Indietro</button>
                        <div class="balls">
                            <div :class="{'step1': true, 'ball': true, 'active-step': this.activeStep>=1}" @click="gotostep(1)"></div>
                            <div :class="{'step2': true, 'ball': true, 'active-step': this.activeStep>=2}" @click="gotostep(2)"></div>
                            <div :class="{'step3': true, 'ball': true, 'active-step': this.activeStep>=3}" @click="gotostep(3)"></div>
                            <div :class="{'step4': true, 'ball': true, 'active-step': this.activeStep>=4}" @click="gotostep(4)"></div>
                            <div :class="{'step5': true, 'ball': true, 'active-step': this.activeStep>=5}" @click="gotostep(5)"></div>
                        </div>
                        <div class="button-proxy">
                            <button v-if="this.activeStep<5" type="button" class="btn" @click="nextStep"><span>Avanti</span></button>
                            <button v-else type="submit" class="btn light" @click.once="processForm">Invia</button>
                            <!--<input v-if="this.activeStep>=5" class="btn" type="submit" value="Invia">-->
                        </div>
                    </div>

                </form>

            </div>

        </div>
    </div>
</div>
</template>

<script>
import ProjectTile from '../components/ProjectTile.vue'
import SpallaProject from '../components/SpallaProject.vue'
import { fetchNodes, fetchRoutes, fetchTaxonomy } from '../libs/drupalClient'
import { loadScript } from "@paypal/paypal-js"

export default {
    components: { 
        ProjectTile, SpallaProject
    },
    name: 'richiedi-confronto',
    data: () => {
        return {
            pageData: { 
                attributes: {
                    title: "Richiedi Confronto",
                    body: {
                        summary: ""
                    }
                } 
            },
            currentPageId: "",
            //activeStep: 1,
            projectData: [],
            expertData: {},
            filtersData: [],
            routesData: [],
            formData: {
                Nome: "",
                Cognome: "",
                LuogoNascita: "",
                DataNascita: "",
                Scuola: 0,
                Indirizzo: "",
                Email: "",
                Cellulare: "",
                DiCosaParlare: "",
                Raccontaci: "",
                Autorizzo: false,
                ExpertId: ""
            },
            selectedProject: -1,    //per il progetto selezionato a cui donare
            donations: [],          //per i diversi campi donazione dei diversi paypal buttons
            donationData: {         //per i dati finali della donazione
                PaypalEmail: "",
                PaypalNome: "",
                PaypalCognome: "",
                Donazione: 0,
                ProjectId: "",
                ProjectName: ""
            },
            erroreNome: false,
            erroreCognome: false,
            erroreEmail: false,
            erroreAutorizzo: false,
            erroreTA1: false,
            erroreTA2: false,
            expertfilters: [],
            ppbutton_added: -1,
            showSpalla: false,
            inputIsDate: false
        }
    },
    methods: {
        focusDate() {
            if(!this.inputIsDate)
                document.getElementById('input_date').blur()

            document.getElementById('input_date').type = 'date'
            this.inputIsDate = true
        },
        liveCountDown(msg) {
            //console.log(msg.match(/\s+/g)?.length || 0)
            return msg.match(/\s+/g)?.length || 0
        },
        nextStep() {

            if(this.activeStep==2 && this.formData.Nome=="")
                this.erroreNome = true

            if(this.activeStep==2 && this.formData.Cognome=="")
                this.erroreCognome = true

            if(this.activeStep==2 && (this.formData.Email=="" || !document.getElementById('email').checkValidity()))
                this.erroreEmail = true

            if(this.activeStep==2 && !this.formData.Autorizzo)
                this.erroreAutorizzo = true

            if(this.activeStep==3 && this.liveCountDown(this.formData.DiCosaParlare) > 100)
                this.erroreTA1 = true

            if(this.activeStep==3 && this.liveCountDown(this.formData.Raccontaci) > 100)
                this.erroreTA2 = true

            if(this.activeStep<5 && !(this.erroreNome || this.erroreCognome || this.erroreEmail || this.erroreAutorizzo || this.erroreTA1 || this.erroreTA2))
                //this.activeStep++;
                this.$store.commit('SET_ACTIVE_STEP', this.activeStep+1)
            
            window.scrollTo({
                top: document.getElementById('richiedi').getBoundingClientRect().top,
                behavior: 'smooth'
            })
        },
        correct() {
            if(this.activeStep==2 && this.formData.Nome!="")
                this.erroreNome = false

            if(this.activeStep==2 && this.formData.Cognome!="")
                this.erroreCognome = false

            if(this.activeStep==2 && this.formData.Email!="")
                this.erroreEmail = false

            if(this.activeStep==2 && this.formData.Autorizzo)
                this.erroreAutorizzo = false

            if(this.activeStep==3 && this.liveCountDown(this.formData.DiCosaParlare) <= 100)
                this.erroreTA1 = false

            if(this.activeStep==3 && this.liveCountDown(this.formData.Raccontaci) <= 100)
                this.erroreTA2 = false
        },
        processForm(event) {
            event.preventDefault()
            //if(this.formData.Nome!="" && this.formData.Cognome!="" && this.formData.Email!="" && this.formData.Autorizzo
                //&& this.liveCountDown(this.formData.DiCosaParlare) <= 100 && this.liveCountDown(this.formData.Raccontaci) <= 100) {
            if(!this.erroreNome && !this.erroreCognome && !this.erroreEmail && !this.erroreAutorizzo && !this.erroreTA1 && !this.erroreTA2) {
                this.formData.ExpertId = this.currentExpertId 

                var url = new URL(process.env.VUE_APP_POSTURL + "/richiesta-confronto");
                //aggiungo i campi del form uno a uno
                Object.entries(this.formData).forEach(([k, v]) => url.searchParams.append(k, v));
                //aggiungo i campi dell'ultima donazione effettuata salvata in precedenza
                Object.entries(this.donationData).forEach(([k, v]) => url.searchParams.append(k, v));

                fetch(url, {
                    method: "POST",
                    redirect: "follow"
                })
                .then(res => { 
                    if(res.status==200) {
                        this.$store.commit('SET_FORM_DATA', {
                            type: "richiesta-confronto",
                            name: this.formData.Nome,
                            surname: this.formData.Cognome,
                            email: this.formData.Email,
                            donation_amount: this.donationData.Donazione,
                            donation_project: this.donationData.ProjectName,
                            expert_contact: this.expertData.attributes.field_name + " " + this.expertData.attributes.field_surname
                        })
                        this.$router.push('/confirmed')
                    }
                })
                .catch(error => { console.log(error) })
            }
        },
        prevStep() {
            //this.activeStep--;
            this.$store.commit('SET_ACTIVE_STEP', this.activeStep-1)
            if(this.activeStep<1)
                //this.activeStep=1;
                this.$store.commit('SET_ACTIVE_STEP', 1)
        },
        gotostep(num) {
            //this.activeStep = num
            this.$store.commit('SET_ACTIVE_STEP', num)
        },
        select(index) {
            this.selectedProject = index
        },
        openSpalla(index) {
            this.showSpalla = true
            console.log(this.projectData[index])
            document.body.classList.add('no-scroll')
            window.scrollTo({
                top: document.body.getBoundingClientRect().top,
                behavior: 'smooth'
            })
        },
        closeSpalla() {
            document.body.classList.remove('no-scroll')
            this.showSpalla = false
        },
        createOrder(data, actions) {
            return actions.order.create({
                purchase_units: [{
                    amount: {
                        value: this.donations['donation'+this.selectedProject],
                        breakdown: {
                            item_total: {
                                currency_code: 'EUR',
                                value: this.donations['donation'+this.selectedProject]
                            }
                        }
                    },
                    items: [
                        {
                            name: this.projectData[this.selectedProject].attributes.title,
                            description: this.projectData[this.selectedProject].attributes.drupal_internal__nid,
                            category: "DONATION",
                            unit_amount: {
                                currency_code: 'EUR',
                                value: this.donations['donation'+this.selectedProject]
                            },
                            quantity: '1'
                        }
                    ]
                }]
            });
        },
        onApprove(data, actions) {
            var tot = this
            return actions.order.capture().then(function(orderData) {
                tot.donationData.PaypalEmail = orderData.payer.email_address
                tot.donationData.PaypalNome = orderData.payer.name.given_name
                tot.donationData.PaypalCognome = orderData.payer.name.surname
                tot.donationData.Donazione = orderData.purchase_units[0].amount.value
                tot.donationData.ProjectId = orderData.purchase_units[0].items[0].description
                tot.donationData.ProjectName = orderData.purchase_units[0].items[0].name
            })
        }
    },
    computed: {
        activeStep() {
            return this.$store.state.richiestaStep
        },
        fotoprofilo() {
            if(this.expertData.field_expert_photo)
                return "--foto: url(" + process.env.VUE_APP_ENDPOINT + this.expertData.field_expert_photo.attributes.uri.url + ");"
            else 
                return ""
        },
        lang() {
            return this.$store.state.currentLang
        },
        defaultLang() {
            return this.$store.state.defaultLang
        },
        currentExpertId () {
            return localStorage.getItem('LS_REQUEST_EXPERT')
        }
    },
    async mounted() {
        //console.log('request to expert: ', localStorage.getItem('LS_REQUEST_EXPERT'))
        if(localStorage.getItem('LS_REQUEST_EXPERT')=='' || localStorage.getItem('LS_REQUEST_EXPERT')==null || !localStorage.getItem('LS_REQUEST_EXPERT'))
            this.$router.push('/gli-esperti')

        console.log(localStorage.getItem('LS_REQUEST_EXPERT'))

        await fetchRoutes().then(r => this.routesData = r.routes)

        await fetchNodes('project', { 
            include: ['field_project_foto', 'field_project_settore', 'field_project_promotori']
        }, this.lang)
        .then(res => {
            this.projectData = res
            this.projectData.forEach((p,i) => {
                this.donations['donation'+i] = 5
            })
        })

        await fetchTaxonomy('ambito_di_esperienza', {}, this.lang).then(res => {
            this.expertfilters = res
        })

        await fetchTaxonomy('settore', {}, this.lang).then(res => {
            this.filtersData = res
        })

        this.formData.ExpertId = this.currentExpertId

        /* if(this.$store.state.currentExpertId=='')
            this.$router.push('/gli-esperti') */
        
        await fetchNodes('expert', {
            filters: [{
                key: 'drupal_internal__nid',
                value: this.currentExpertId
            }],
            include: ['field_expert_photo', 'field_expert_ambito']
        }, this.lang).then(res => {
            this.expertData = res[0]
        })
    },
    beforeDestroy() {
        localStorage.setItem('LS_REQUEST_EXPERT', null)
        this.closeSpalla()
    },
    updated() {
        const vp = this
        this.ppbutton_added!=this.selectedProject && this.activeStep==4 && loadScript({ 
            //LIVE EXPEROTARY
            "client-id": "AYCU0wAT4EeOVFV2t3u5F8HaWMDOZcYgprf7jkvSQNeGwx7YabPB93ThCeHosIo3EKb4sv4LC1dOxj8P",
            //SANDBOX
            //"client-id": "AfGn9zITTU6yiwCSbWrqdQsJZ4xsTa1EzDb1XPLJvBL92EHJLzTyGpwUN-ZUyjYGv5e7f0pzxbgRl0Ts",
            "currency": "EUR", 
            "disable-funding": "credit,card,venmo,sofort,mybank" 
        })
        .then((paypal) => {
            vp.ppbutton_added = this.selectedProject

            paypal.Buttons({
                createOrder: this.createOrder,
                onApprove: this.onApprove,
                style: {
                    layout: 'vertical',
                    color: 'gold',
                    shape: 'pill',
                    label: 'donate'
                }
            })
            .render("#ppbutton"+this.selectedProject)
            .catch((error) => {
                vp.ppbutton_added = -1
                console.error("failed to create PayPalButton", error);
            });
        })
        .catch(error => {
            console.error("failed to load the PayPal JS SDK script", error);
        })
    }
}
</script>

<style lang="scss" scoped>
#input_date {
    -webkit-appearance: none;
    height: 3.7em;
    &::-webkit-calendar-picker-indicator {
        width: 100%;
        padding: 0;
        margin:  0;
        top: 0;
        left: 0;
        position: absolute;
        background: transparent;
        box-sizing: border-box;
        text-align: right; 
        height: 100%;
    }

    &::after {
        content: '';
        pointer-events: none;
        width: 1.5em;
        height: 1.5em;
        position: absolute;
        right: 1.5em;
        top: 50%;
        transform: translateY(-50%);
        display: block;
        background: url('../assets/calendar.png');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
    }
    
}
</style>